<template>
  <div class="pt-6">
    <div class="mb-6 text-center text-2xl text-black font-700">
      {{ t('howToEarnTitle') }}
    </div>
    <div v-for="item in HowToEarnOptions" :key="item.icon" class="flex items-center px-8 pb-8">
      <div
        class="h-6 w-6 flex-shrink-0 rounded-full bg-[#1b1b1b] text-center text-base text-white font-500 leading-6"
      >
        {{ item.icon }}
      </div>
      <img class="mx-5 w-12 flex-shrink-0" :src="item.image" />
      <div class="text-xs text-[#363636] font-400">
        {{ item.description }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ImageHowToEarn1 from '@/assets/images/home/how_to_earn_1.png'
import ImageHowToEarn2 from '@/assets/images/home/how_to_earn_2.png'
import ImageHowToEarn3 from '@/assets/images/home/how_to_earn_3.png'

const { t } = useI18n()

const HowToEarnOptions = computed(() => [
  {
    icon: '1',
    image: ImageHowToEarn1,
    description: t('howToEarnDesc1'),
  },
  {
    icon: '2',
    image: ImageHowToEarn2,
    description: t('howToEarnDesc2'),
  },
  {
    icon: '3',
    image: ImageHowToEarn3,
    description: t('howToEarnDesc3'),
  },
])
</script>

<i18n lang="json">
{
  "en": {
    "howToEarnTitle": "How to earn",
    "howToEarnDesc1": "Choose from 1000+ attractions worldwide and book the your desired ticket.",
    "howToEarnDesc2": "Compare global prices and book the most affordable ticket.",
    "howToEarnDesc3": "Earn your favorite rewards within 2 weeks after visiting your favorite attraction."
  }
}
</i18n>
